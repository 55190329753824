// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blogpage-js": () => import("./../../../src/pages/blogpage.js" /* webpackChunkName: "component---src-pages-blogpage-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-essentialguide-js": () => import("./../../../src/pages/essentialguide.js" /* webpackChunkName: "component---src-pages-essentialguide-js" */),
  "component---src-pages-faqpage-js": () => import("./../../../src/pages/faqpage.js" /* webpackChunkName: "component---src-pages-faqpage-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/homePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mediapage-js": () => import("./../../../src/pages/mediapage.js" /* webpackChunkName: "component---src-pages-mediapage-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

